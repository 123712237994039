import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';
import { map, catchError } from 'rxjs/operators';
import { AppSettings } from 'src/app/helpers/app_settings';
import { User } from 'src/app/model/user';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  mediciones = this.firestore.collection('mediciones');

  constructor(private firestore: AngularFirestore) { }

  public getData(): Observable<User[]> {
    return this.mediciones.snapshotChanges().pipe(
      map(AppSettings.extractData),
      catchError(AppSettings.handleErrorObservable)
    );
  }

  public getDataByMedidor(numMedidor: string): Observable<User[]> {
    // this.firestore.collection('personas').doc(Nombre).get().subscribe(
    //   doc => {
    //     console.log(doc.data());
    //   }
    // );

    return this.firestore.collection('mediciones', ref => ref.orderBy('NMedidor')
        .limit(10).startAt(numMedidor).endAt(numMedidor + '\uf8ff'))
        .snapshotChanges().pipe(
          map(AppSettings.extractData),
          catchError(AppSettings.handleErrorObservable)
        );

  }

  // public getData(name: string) {
  //   return this.firestore.collection('cats').doc(name).snapshotChanges();
  // }

}
