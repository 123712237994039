import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UploadMeasuresRoutes } from './modules/upload-measures/components/upload-measures/upload-measures.routes';
import { UploadMeasuresComponent } from './modules/upload-measures/components/upload-measures/upload-measures.component';
import { UsersRoutes } from './modules/users/components/users/users.routes';
import { UsersComponent } from './modules/users/components/users/users.component';

const routes: Routes = [
  ...UsersRoutes,
  // ...UploadMeasuresRoutes,
  { path: '', component: UsersComponent },
  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
