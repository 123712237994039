
export class User {
  id: string;
  Nombre: string;
  Direccion: string;
  Periodo: string;
  NPartida: string;
  NMedidor: string;
  MedActual: string;
  Fecha: string;
  Novedades: string;
  Consumo: string;

  constructor() {
  }

}
