import { Component, OnInit } from '@angular/core';
import { User } from '../../../../model/user';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'cfe-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  errorMessage: String;
  users: User[];
  closeResult: string;
  page = 1;
  persona: User;
  searchParam: string;
  colorError: string;

  constructor(
    private userService: UserService
  ) {}

  ngOnInit() {
    this.initialize();
    // this.loadTable();
  }

  initialize() {
    this.users = [];
    this.persona = new User();
    this.searchParam = '';
    this.colorError = '';
  }

  pageChange() {
    // this.loadTable();
  }

  // loadTable() {
  //   this.userService.getPersonas().subscribe(
  //     personas => {
  //       personas.forEach(persona => {
  //         // console.log(persona.payload.doc.data());
  //         this.users.push(persona.payload.doc.data());
  //       });
  //     }
  //   );
  // }

  crear() {
    // console.log(this.persona);
    // const data = {nombre: this.persona.nombre, dni: this.persona.dni};
    // this.userService.createPersona(data);
    this.initialize();
  }

  search() {
    this.users = [];
    this.errorMessage = '';
    // console.log(this.searchParam);
    if (this.searchParam.trim() !== '') {
      if (this.searchParam.trim().length > 2) {
        // this.userService.getDataByName(this.searchParam);
        this.userService.getDataByMedidor(this.searchParam.trim()).subscribe(
          data => {
            // tslint:disable-next-line:max-line-length
            data = data.sort((a, b) => this.parse(b.Fecha.substring(0, 10).trim()).getTime() - this.parse(a.Fecha.substring(0, 10).trim()).getTime());
            console.log(data);
            data.forEach(user => {
              // if (user.Nombre.includes(this.searchParam.toUpperCase())) {
                const medAnterior = data.find(d => d.NMedidor.trim() === user.NMedidor.trim()
                    && this.parse(d.Fecha.substring(0, 10).trim()) < this.parse(user.Fecha.substring(0, 10).trim()));
                console.log(medAnterior);
                if (typeof medAnterior !== 'undefined') {
                  user.Consumo = String(+user.MedActual - +medAnterior.MedActual);
                } else {
                  user.Consumo = '-';
                }
                this.users.push(user);
              // }
            });
            // tslint:disable-next-line:max-line-length
            this.users = this.users.sort((a, b) => this.parse(b.Fecha.substring(0, 10).trim()).getTime() - this.parse(a.Fecha.substring(0, 10).trim()).getTime());
            if (this.users.length === 0) {
              this.colorError = 'red';
              this.errorMessage = 'LA BÚSQUEDA NO TRAJO RESULTADOS';
              this.searchParam = this.searchParam.trim();
            }
            this.searchParam = '';
          }
        );
      } else {
        this.colorError = 'red';
        this.errorMessage = 'EL CAMPO BÚSQUEDA DEBE CONTENER COMO MÍNIMO 3 LETRAS/NÚMEROS';
        this.searchParam = this.searchParam.trim();
      }
    } else {
      this.searchParam = '';
      this.colorError = 'red';
      this.errorMessage = 'EL CAMPO BÚSQUEDA NO PUEDE SER VACÍO';
      this.searchParam = this.searchParam.trim();
    }
  }

  parse(value: string): Date {
    if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
      const str = value.split('/');

      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);

      return new Date(year, month, date);
    } else if ((typeof value === 'string') && value === '') {
      return new Date();
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

}
