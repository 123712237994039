import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse} from '@angular/common/http';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      // add authorization header with jwt token if available
      const cenitCurrentUser = JSON.parse(localStorage.getItem('cenitCurrentUser'));
      if (cenitCurrentUser && cenitCurrentUser.token) {
          request = request.clone({
              setHeaders: {
                  Authorization: `${cenitCurrentUser.token}`
              }
          });
      }

      return next.handle(request).pipe(
        tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // do stuff with response if you want
          }
        }, (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401 || err.status === 403) {
                this.router.navigate(['/login']);
            }
          }
        }));
  }
}
