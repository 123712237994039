import { Injectable } from '@angular/core';
import { AngularFirestore } from 'angularfire2/firestore';

@Injectable({
  providedIn: 'root'
})
export class UploadMeasuresService {

  mediciones = this.firestore.collection('mediciones');

  constructor(private firestore: AngularFirestore) { }

  public save(data: {name: string, address: string, period: string,
        measureNumber: string, currentMeasurement: string, date: string, news: string}) {
    return this.mediciones.add(data);
  }

  public async deleteAll() {
    this.mediciones.get().subscribe(users => {
      users.forEach(async doc => {
        await this.mediciones.doc(doc.id).delete();
      });
    });
  }

}
