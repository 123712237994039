import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { UploadMeasuresComponent } from './components/upload-measures/upload-measures.component';
import { UploadMeasuresService } from './services/upload-measures.service';
@NgModule({
  imports: [CommonModule, BrowserAnimationsModule, FormsModule, NgbModule.forRoot(), NgbModalModule],
  declarations: [UploadMeasuresComponent],
  entryComponents: [],
  providers: [UploadMeasuresService]
})
export class UploadMeasuresModule {}
