import { Component, OnInit } from '@angular/core';
import { UploadMeasuresService } from '../../services/upload-measures.service';

@Component({
  selector: 'cfe-upload-measures',
  templateUrl: './upload-measures.component.html',
  styleUrls: ['./upload-measures.component.css']
})
export class UploadMeasuresComponent implements OnInit {

  errorUpload: String;
  colorError: string;
  errorDelete: string;
  colorDelete: string;
  cantidadSubidos = 0;
  cantidadError = 0;
  showLoading = false;
  showDeleteLoading = false;
  disabledUpload = false;
  disabledDelete = false;

  constructor(
    private uploadMeasuresService: UploadMeasuresService
  ) {}

  ngOnInit() {
    this.initialize();
  }

  initialize() {
    this.errorUpload = '';
    this.colorError = '';
    this.errorDelete = '';
    this.colorDelete = '';
  }

  convertFile() {
    this.showUploadMessagge(false);
    const input = <HTMLInputElement>document.getElementById('fileInput');
    let cantidadTotal = 0;
    this.showLoading = true;
    this.showDeleteLoading = false;

    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result;
      // console.log('CSV: ', text.substring(0, 100) + '...');

      // const csvFilePath = text;
      const csv = require('csvtojson');
      // csv()
      // .fromFile(csvFilePath)
      // .then((jsonObj) => {
      //     console.log(jsonObj);
      // });

      csv({
          noheader: false,
          output: 'json',
          delimiter: ';'
      })
      .fromString(text)
      .then((csvRow) => {
        const cantRow = csvRow.length;
        csvRow.forEach(element => {
          const result = this.uploadMeasuresService.save(element);
          // console.log(result);
          result.then(doc => {
            // console.log('then doc: ' + doc);
            this.cantidadSubidos++;
            cantidadTotal++;
            if (cantidadTotal === cantRow) {
              this.showUploadMessagge(true);
            }
          })
          .catch(doc => {
            // console.log('catch doc: ' + doc);
            this.cantidadError++;
            cantidadTotal++;
            if (cantidadTotal === cantRow) {
              this.showUploadMessagge(true);
            }
          });
        });
        // console.log(csvRow);
      });

      // const options = {
      //   complete: (results, file) => {
      //     results.forEach(element => {

      //     });
      //       console.log('Parsed: ', results, file);
      //   }
      //   // Add your options here
      // };

      // this.papa.parse(text, options);

      // convert text to json here
      // var json = this.csvJSON(text);
    };
    reader.readAsText(input.files[0]);
  }

  showUploadMessagge(show: boolean) {
    if (show) {
      this.errorDelete = '';
      this.showLoading = false;
      this.errorUpload = 'ARCHIVO SUBIDO CON ÉXITO.\n'
          + ' CANTIDAD DE REGISTROS SUBIDOS: \n'
          + ' CANTIDAD DE REGISTROS CON ERROR: ';
      this.colorError = 'green';
      this.disabledDelete = false;
      this.disabledUpload = false;
    } else {
      this.disabledDelete = true;
      this.disabledUpload = true;
      this.showLoading = false;
      this.errorUpload = '';
      this.colorError = '';
      this.errorDelete = '';
    }
  }

  async delete() {
    const ok = window.confirm('Se borrarán todos los registros. Esta seguro de realizar esta acción?');
    if (ok) {
      this.showDeleteMessagge(false);
      // console.log('Se eliminaron todos los registros');
      await this.uploadMeasuresService.deleteAll().then(
        result => {
          console.log(result);
          this.showDeleteMessagge(true);
        }
      )
      .catch(result => {
        console.log(result);
      });

    }
  }

  showDeleteMessagge(show: boolean) {
    if (show) {
      this.colorDelete = 'red';
      this.errorDelete = 'SE ELIMINARON TODOS LOS REGISTROS';
      this.showDeleteLoading = false;
      this.disabledDelete = false;
      this.disabledUpload = false;
    } else {
      this.disabledDelete = true;
      this.disabledUpload = true;
      this.colorDelete = '';
      this.errorDelete = '';
      this.showDeleteLoading = false;
      this.showLoading = false;
      this.errorUpload = '';
      this.colorError = '';
    }
  }

}
