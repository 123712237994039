import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { UsersComponent } from './components/users/users.component';
import { UserService } from './services/user.service';
import { FormsModule } from '@angular/forms';
import { NgbModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
@NgModule({
  imports: [CommonModule, BrowserAnimationsModule, FormsModule, NgbModule.forRoot(), NgbModalModule],
  declarations: [UsersComponent],
  entryComponents: [],
  providers: [UserService]
})
export class UsersModule {}
