import { throwError } from 'rxjs';
import { User } from '../model/user';

export class AppSettings {

  // public static API_ENDPOINT = 'http://localhost:8080/cenit-be';
  // public static API_ENDPOINT = 'http://163.10.181.47:8080/cenit-be';
   //public static API_ENDPOINT = 'https://cenitiot.dat.cespi.unlp.edu.ar/cenit-be';

  public static CANTIDAD_ITEMS_PAGINA = 10;

  public static extractData(res: any) {
      // console.log(res);
      if (res != null) {
        let result: User[];
        result = [];
        res.forEach(data => {
          // console.log(data.payload.doc.data().Nombre);
          if (typeof data.payload.doc.data().Nombre !== 'undefined') {
            result.push(data.payload.doc.data());
          }
        });
        // console.log(result);
        return result;
      } else {
          throw new Error(res.json.toString());
      }
  }

  public static handleErrorObservable(error: Response | any) {
      return throwError(error.message || error);
  }

}
